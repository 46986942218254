.calendar-navigation {
  width: 100%;
  height: 30px;

  .navigation-button {
    padding: 0.25em;

    border-radius: 3px;

    cursor: pointer;

    transition: background-color 150ms linear;

    &:hover {
      background-color: var(--base-bg-darker);
    }
  }

  .date-text {
    font-weight: 500;
  }
}
