.rjsf-review-field {
  .review-field-info {
    background-color: var(--base-bg-dark);
    border-radius: 3px;

    .catalog-provided-info {
      margin-left: 1em;
    }
  }

  .rjsf-review-score {
    height: 100%;
    width: 40px;
    .review-score-label {
      white-space: nowrap;
    }

    .rjsf-review-sectors {
      height: 100%;
      align-items: flex-end;
      .score-sector {
        height: 20px;
        width: 40px;

        opacity: 0.25;

        cursor: pointer;

        transition: opacity 150ms linear;

        &:hover,
        &.selected {
          opacity: 1;
        }
      }
    }
  }
}
