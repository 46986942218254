.mdxeditor-diff-source-wrapper .editable-content {
  all: revert;
}

.md-editor-wrapper {

  // Custom scrollbar, as the default one is no so good for the editor
  scrollbar-width: 1px;

  .mdx-editor-element {

    .mdxeditor-rich-text-editor, .editable-content {
      height: 300px;
    }

    .editable-content {
      font-size: 16px;
      p, blockquote, ul, ol, pre, h1, h2, h3, h4, h5, h6, div {
        all: revert;
      }
      
      h1, h2, h3, h4, h5, h6 {
        all: revert;
      }

      h1 {
        font-size: 3rem;
        font-weight: 400;
        color: #39393a;
      }
      
      h2 {
        font-size: 1.2rem;
        font-weight: bold;
      }

      h3 {
        font-size: 2rem;
        font-weight: 400;
        color: #39393a;
      }

      h4 {
        font-size: 1.3rem;
        font-weight: 700;
        color: #39393a;
      }

      h5 {
        font-size: 1.2rem;
        font-weight: 700;
        color: #39393a;
      }

      h6 {
        font-size: 1.1rem;
        font-weight: 700;
        color: #39393a;
      }

      
      overflow: scroll;
    }
    
  }


  ::-webkit-scrollbar-thumb {
    background: #220000;
  }

  ::-webkit-scrollbar-track {
    background: #CCC;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }
  
  // From https://github.com/ecmwf-projects/cads-webportal/blob/ee91565f009c19069d6dade0473dcfc3f7bbe392/config/shared/constants.ts#L25
  h1 {
    // Obtain the same font size as the one in the editor
    font-size: 3rem;
    font-weight: 400;
    line-height: 1.2;
  }

  h2 {
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 1.2;
  }

  h3 {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.2;
  }

  p {
    font-size: 0.875rem; 
    font-weight: 400;

  }

  // Reset for ul, ol, li
  ul, ol {
    padding-left: 1.5rem;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  .spell-error {
    text-decoration: underline;
    text-decoration-style: wavy;
    text-decoration-color: red;
  }
}

.suggestions-tooltip-body {
  .suggestions-tooltip-suggestion {
    cursor: pointer;
    &:hover {
      color: var(--primary);
    }
  }
}

.mdxeditor-popup-container {
  z-index: 99999 !important;
}
