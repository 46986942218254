@import 'src/assets/styles/partials/shadows';
@import 'src/assets/styles/partials/breakpoints';

@mixin responsive {
  .datepicker-selected {
    .modal-body {
      min-height: 0;
    }
  }
  .modal-backdrop {
    .modal {
      .modal-body {
        transition: none;
        justify-content: center;
      }
    }
  }

  .modal-body {
    flex-direction: row;
    min-height: 0;

    .flex-group {
      height: 0;
    }
  }
}
@include respond-to(small) {
  @include responsive();
}

.datepicker-selected {
  .modal-body {
    min-height: 600px;
  }
}
.modal {
  min-width: 400px;
}

.modal-body {
  transition: all 0.5s ease-in-out;
  min-height: 366px;

  .create-event-button {
    margin-top: 16px;

    svg {
      z-index: auto;
    }
  }
}

.tooltip-wrapper {
  position: relative;

  background-color: var(--base-bg);

  border-radius: 3px;

  padding: 0.75em;

  @include shadow-16dp();

  overflow-y: auto;

  max-height: 400px;

  justify-content: flex-start;

  cursor: default;

  .tooltip-close-icon {
    position: absolute;

    right: 0.25em;
    top: 0.25em;

    color: var(--secondary);

    cursor: pointer;
  }
}

.calendar-tooltip {
  .tooltip-day {
    color: var(--secondary);
  }

  .tooltip-event {
    padding: 0.5em;
    border-radius: 3px;

    .event-name {
      font-weight: 500;

      font-size: 0.9em;
      color: var(--secondary);
    }

    .event-dates {
      font-size: 0.75em;
    }
  }
}
