.images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;

  .image-container {
    margin: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;

    .image-selector {
      margin: 1em;
      overflow: hidden;
      border-radius: 3px;
      display: flex;
      max-height: 80px;
      cursor: pointer;
      object-fit: cover;
      opacity: 0.4;
      transition: linear 0.1s;



      &.active {
        outline: 1px solid var(--primary) !important;
        opacity: 1;
      }

      &.disabled {
        cursor: default;
        outline: none;

        &:hover {
          outline: none;
        }
      }

      &:hover {
        outline: 1px solid var(--primary);
      }
    }
  }
}