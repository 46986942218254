@import 'src/assets/styles/partials/theme';

.history-form-data-string-type-value {
  --remove: #fb6a4a;
  --add: #a1d99b;

  padding: 0.25em 0.5em;
  border-radius: 3px;

  display: flex;
  flex-direction: row;
  align-items: center;

  .change-icon {
    margin-right: 0.5em;
  }

  &.EQUALS {
    background-color: var(--base-bg-darker);
  }

  &.REMOVE {
    background-color: var(--remove);
    color: var(--secondary);
    text-decoration: line-through;

    @include dark-theme() {
      color: var(--base-bg-dark);
    }
  }

  &.ADD {
    background-color: var(--add);
    color: var(--secondary);
    @include dark-theme() {
      color: var(--base-bg-dark);
    }
  }
}

.history-update-string-row {
  flex-wrap: wrap;
}

.history-form-data-array {
  --remove: #fb6a4a;
  --add: #a1d99b;

  .history-form-data-array-value {
    padding: 0.25em 0.5em;
    border-radius: 3px;

    display: flex;
    flex-direction: row;
    align-items: center;

    .change-icon {
      margin-right: 0.5em;
    }

    &.EQUALS {
      background-color: var(--base-bg-darker);
    }

    &.REMOVE {
      background-color: var(--remove);
      color: var(--secondary);
      text-decoration: line-through;
      @include dark-theme() {
        color: var(--base-bg-dark);
      }
    }

    &.ADD {
      background-color: var(--add);
      color: var(--secondary);
      @include dark-theme() {
        color: var(--base-bg-dark);
      }
    }
  }
}

.history-form-data-bool-value {
  --remove: #fb6a4a;
  --add: #a1d99b;

  padding: 0.25em 0.5em;
  border-radius: 3px;

  display: flex;
  flex-direction: row;
  align-items: center;

  .change-icon {
    margin-right: 0.5em;
  }

  &.EQUALS {
    background-color: var(--base-bg-darker);
  }

  &.REMOVE {
    background-color: var(--remove);
    color: var(--secondary);
    text-decoration: line-through;
    @include dark-theme() {
      color: var(--base-bg-dark);
    }
  }

  &.ADD {
    background-color: var(--add);
    color: var(--secondary);
    @include dark-theme() {
      color: var(--base-bg-dark);
    }
  }
}

.history-form-data-review-field {
  .rjsf-fix-review-comment {
    .history-form-data-string-type-value {
      font-size: 14px;
    }

    .review-score {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;

      gap: 0.5em;

      margin-top: 1em;

      &.add,
      &.remove {
        .review-score-text {
          color: white;
        }
      }
    }
  }
}

.history-form-data-md-field {
  .md-preview-group {
    align-items: flex-start;

    .md-preview-group-title {
      font-size: 14px;

      color: var(--primary);

      margin-bottom: -2em;
      margin-top: 1em;
    }
  }
}

.history-form-data-images-wrapper {
  --remove: #fb6a4a;
  --add: #a1d99b;

  justify-content: flex-start;
  margin-top: 0.5em;

  .qar-view-image-wrapper {
    width: 150px;
    height: 170px;

    justify-content: flex-start;

    .qar-view-image {
      width: 150px;
      height: 150px;
      border-radius: 3px;
    }

    .qar-view-image-text-wrapper {
      width: auto;
      padding: 0px 0.5em;
      border-radius: 3px;

      .qar-view-image-text {
        font-size: 0.8em;
        text-transform: uppercase;
      }
    }

    &.EQUALS {
      .qar-view-image-text-wrapper {
        background-color: var(--base-bg-darker);
        color: var(--secondary);
      }
    }

    &.REMOVE {
      .qar-view-image-text-wrapper {
        background-color: var(--remove);
        color: var(--secondary);
        text-decoration: line-through;

        @include dark-theme() {
          color: var(--base-bg-dark);
        }
      }
    }

    &.ADD {
      .qar-view-image-text-wrapper {
        background-color: var(--add);
        color: var(--secondary);
        @include dark-theme() {
          color: var(--base-bg-dark);
        }
      }
    }
  }
}
