@import 'src/assets/styles/partials/theme';

.select {
  .cop-select__control {
    border-radius: 3px;
    font-weight: normal;
    border-width: 1px;
    border-color: var(--secondary);
    min-height: 32px;

    background-color: var(--base-bg);
    overflow: hidden;

    @include dark-theme() {
      background-color: var(--base-bg-dark);
    }

    .cop-select__value-container {
      padding-left: 18px;

      .cop-select__single-value {
        color: var(--secondary);
      }

      .cop-select__input-container {
        color: var(--secondary);
      }
    }

    .cop-select__indicators {
      height: 100%;
      transition: transform 150ms linear;
      .cop-select__indicator-separator {
        display: none;
      }
    }
    &.cop-select__control--is-focused {
      border-color: var(--primary);
      box-shadow: none;
    }

    &.cop-select__control--menu-is-open {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom-color: transparent;

      .cop-select__indicators {
        transform: rotate(180deg);
      }
    }
  }

  .cop-select__menu {
    height: auto;

    top: calc(100% - 2px);

    margin-top: 0px;

    border: 1px solid var(--primary);
    border-top: none;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;

    box-sizing: border-box;

    box-shadow: none;

    background-color: var(--base-bg-dark);

    z-index: 1000;

    .cop-select__menu-list {
      box-sizing: border-box;
      padding: 4px 8px;
      .cop-select__option {
        display: flex;
        align-items: center;

        width: 100%;
        height: 32px;

        border-radius: 4px;

        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: normal;
        text-align: start;
        color: var(--secondary);

        white-space: nowrap;
        text-overflow: ellipsis;

        cursor: pointer;

        overflow: hidden;

        transition: color 50ms linear, background-color 50ms linear;

        &.cop-select__option--is-focused {
          color: var(--primary);
          background-color: var(--base-bg);

          @include dark-theme() {
            color: var(--secondary);
            background-color: var(--base-bg-darker);
          }
        }

        &.cop-select__option--is-selected {
          background-color: var(--primary);
          color: var(--base-bg);

          @include dark-theme() {
            color: var(--secondary);
          }
        }
      }
    }
  }
}
