@import 'src/assets/styles/partials/shadows';

.chat-button {
  position: fixed;

  bottom: 1em;
  right: 1em;

  border-radius: 50%;

  padding: 0.75em;
  height: unset;
  max-height: unset;
}

.chat-container {
  background-color: var(--base-bg);

  border-radius: 3px;

  min-height: 500px;
  max-height: 500px;
  width: 100%;

  overflow: hidden;

  @include shadow-4dp();

  .tippy-content {
    height: 500px;
    overflow: hidden;

    & > div {
      height: 500px;
      overflow: hidden;
    }
  }

  .chat {
    width: 100%;
    height: 100%;

    align-items: flex-end;
    justify-content: flex-end;
    overflow: hidden;

    .chat-title {
      height: 40px;

      border-bottom: 1px solid var(--base-bg-dark);
      background-color: var(--base-bg-dark);

      padding: 0px 1em;

      .chat-title-message {
        font-size: 0.8em;
      }
    }

    .messages-container {
      width: 100%;
      max-height: calc(100% - 105px);

      justify-content: flex-start;

      flex-grow: 1;

      overflow: auto;
      padding: 1em 0px 1em 1em;

      .no-messages-text {
        font-size: 1.25em;

        text-align: center;
        letter-spacing: 2px;
      }

      &.no-messages {
        justify-content: center;
        padding: 1em;
      }
    }

    .chat-input-form {
      width: 100%;
      min-height: 65px;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      flex-wrap: nowrap;

      gap: 0.5em;
      padding: 1em;

      border-top: 1px solid var(--base-bg-darker);

      .input {
        flex-grow: 1;
      }

      .chat-input-form-button {
        border-radius: 50%;

        padding: 0.5em;
        height: unset;
        max-height: unset;
      }
    }
  }
}

.chat-date-indicator {
  height: auto;
  .date {
    font-size: 0.6em;
    font-weight: 500;

    letter-spacing: 2px;

    background-color: rgba(var(--primary-raw), 0.5);
    color: var(--base-bg);

    padding: 2px 4px;
    border-radius: 3px;
  }
}

.chat-message {
  width: 90%;
  height: auto;

  gap: 0px;
  align-items: flex-start !important;
  justify-content: flex-start !important;

  background-color: var(--base-bg-dark);
  border: 1px solid var(--base-bg-darker);

  padding: 0.5em;
  border-radius: 3px;

  .chat-message-header {
    .chat-message-user {
      font-size: 0.75em;
      font-weight: bold;
    }
    .chat-message-role {
      font-size: 0.6em;

      background-color: var(--base-bg-darker);
      margin-left: 0.5em;
      padding: 2px 4px;
      border-radius: 3px;
    }
  }

  .chat-message-content {
    font-size: 0.9em;
  }

  .chat-message-date {
    font-size: 0.6em;
    font-weight: bold;
  }

  &.owned {
    position: relative;

    border-color: var(--primary);
    left: calc(10% - 1em);
  }
}
