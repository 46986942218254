.heading {

  &.lvl-1 {
    font-size: 48px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: 1.5px;
  }
  &.lvl-2 {
    font-size: 40px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: 1.5px;
  }
  &.lvl-3 {
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 1px;
    text-align: center;
  }
  &.lvl-4 {
    
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 0.75px;
    text-align: center;
  }
  &.lvl-5 {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #fffcea88;
  }
  &.lvl-6 {
  }
}
