.reference-modal {
  .modal {
    width: 75%;
    height: auto;

    .references-fieldset {
      width: 100%;
      height: auto;
      border: 1px solid var(--primary);
      border-radius: 4px;

      .references-fieldset-legend {
        font-size: 1.25em;
        text-transform: uppercase;
        color: var(--primary);
      }
    }

    .array-child-item {
      padding-bottom: 1em;
      border-bottom: 2px solid var(--primary);
    }

    .input {
      width: 100%;
    }

    .select {
      width: 100%;
    }
  }
}

.md-editor-references {
  padding: 1em;
  align-items: flex-start;

  .reference {
    background-color: var(--base-bg-dark);
    padding: 0.5em 1em;

    .reference-index {
      font-size: 0.8em;
      width: 32px;
    }

    .reference-input {
      height: 32px;
      width: 100%;
      flex-grow: 1;
    }

    .reference-content {
      width: 100%;
      text-align: start;
      font-size: 0.9rem;
    }

    .add-reference-button {
      padding: 0.25em;
    }
  }
}
