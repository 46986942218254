:root {
  // @media (prefers-color-scheme: light) {
  // Raw colors
  --primary-raw: 148, 19, 51;

  --primary-light-raw: 169, 66, 92;
  --primary-lighter-raw: 191, 113, 133;

  --primary-dark-raw: 118, 15, 41;
  --primary-darker-raw: 89, 11, 31;

  --primary-accent-raw: 240, 223, 222;
  --primary-light-accent-raw: 240, 223, 222;
  --primary-dark-accent-raw: 240, 223, 222;

  --base-bg-raw: 245, 247, 252;
  --base-bg-dark-raw: 240, 240, 240;
  --base-bg-darker-raw: 222, 221, 221;

  --secondary-raw: 33, 35, 46;

  --secondary-light-raw: 77, 79, 88;
  --secondary-lighter-raw: 122, 123, 130;

  --secondary-dark-raw: 26, 28, 37;
  --secondary-darker-raw: 20, 21, 28;

  --secondary-accent-raw: 90, 88, 88;

  --error-raw: 240, 62, 62;
  --success-raw: 57, 149, 57;
  --info-raw: 100, 100, 246;
  --warning-raw: 255, 165, 0;

  $primary: rgb(148, 19, 51);

  $primary-light: rgb(169, 66, 92);
  $primary-lighter: rgb(191, 113, 133);

  $primary-dark: rgb(118, 15, 41);
  $primary-darker: rgb(89, 11, 31);

  $primary-accent: rgb(240, 223, 222);
  $primary-light-accent: rgb(240, 223, 222);
  $primary-dark-accent: rgb(240, 223, 222);

  $base-bg: rgb(245, 247, 252);
  $base-bg-dark: rgb(240, 240, 240);
  $base-bg-darker: rgb(222, 221, 221);

  $secondary: rgb(33, 35, 46);

  $secondary-light: rgb(77, 79, 88);
  $secondary-lighter: rgb(122, 123, 130);

  $secondary-dark: rgb(26, 28, 37);
  $secondary-darker: rgb(20, 21, 28);

  $secondary-accent: rgb(90, 88, 88);

  $error: rgb(240, 62, 62);
  $success: rgb(57, 149, 57);
  $info: rgb(100, 100, 246);
  $warning: rgb(255, 165, 0);
  // }

  // @media (prefers-color-scheme: dark) {
  //   // Raw colors
  //   --primary-raw: 152, 39, 67;

  //   --primary-light-raw: 169, 66, 92;
  //   --primary-lighter-raw: 191, 113, 133;

  //   --primary-dark-raw: 118, 15, 41;
  //   --primary-darker-raw: 89, 11, 31;

  //   --primary-accent-raw: 240, 223, 222;
  //   --primary-light-accent-raw: 240, 223, 222;
  //   --primary-dark-accent-raw: 240, 223, 222;

  //   --base-bg-raw: 33, 33, 33;
  //   --base-bg-dark-raw: 50, 50, 50;
  //   --base-bg-darker-raw: 97, 97, 97;

  //   --secondary-raw: 238, 238, 238;

  //   --secondary-light-raw: 245, 245, 245;
  //   --secondary-lighter-raw: 250, 250, 250;

  //   --secondary-dark-raw: 224, 224, 224;
  //   --secondary-darker-raw: 189, 189, 189;

  //   --secondary-accent-raw: 90, 88, 88;

  //   --error-raw: 240, 62, 62;
  //   --success-raw: 57, 149, 57;
  //   --info-raw: 100, 100, 246;
  //   --warning-raw: 255, 165, 0;

  //   $primary: rgb(152, 39, 67);

  //   $primary-light: rgb(169, 66, 92);
  //   $primary-lighter: rgb(191, 113, 133);

  //   $primary-dark: rgb(118, 15, 41);
  //   $primary-darker: rgb(89, 11, 31);

  //   $primary-accent: rgb(240, 223, 222);
  //   $primary-light-accent: rgb(240, 223, 222);
  //   $primary-dark-accent: rgb(240, 223, 222);

  //   $base-bg: rgb(33, 33, 33);
  //   $base-bg-dark: rgb(50, 50, 50);
  //   $base-bg-darker: rgb(97, 97, 97);

  //   $secondary: rgb(238, 238, 238);

  //   $secondary-light: rgb(245, 245, 245);
  //   $secondary-lighter: rgb(250, 250, 250);

  //   $secondary-dark: rgb(224, 224, 224);
  //   $secondary-darker: rgb(189, 189, 189);

  //   $secondary-accent: rgb(90, 88, 88);

  //   $error: rgb(240, 62, 62);
  //   $success: rgb(57, 149, 57);
  //   $info: rgb(100, 100, 246);
  //   $warning: rgb(255, 165, 0);
  // }

  // Ready to use colors
  --primary: rgb(var(--primary-raw));

  --primary-light: rgb(var(--primary-light-raw));
  --primary-lighter: rgb(var(--primary-lighter-raw));

  --primary-dark: rgb(var(--primary-dark-raw));
  --primary-darker: rgb(var(--primary-darker-raw));

  --primary-accent: rgb(var(--primary-accent-raw));
  --primary-light-accent: rgb(var(--primary-light-accent-raw));
  --primary-dark-accent: rgb(var(--primary-dark-accent-raw));

  --base-bg: rgb(var(--base-bg-raw));
  --base-bg-dark: rgb(var(--base-bg-dark-raw));
  --base-bg-darker: rgb(var(--base-bg-darker-raw));

  --secondary: rgb(var(--secondary-raw));

  --secondary-light: rgb(var(--secondary-light-raw));
  --secondary-lighter: rgb(var(--secondary-lighter-raw));

  --secondary-dark: rgb(var(--secondary-dark-raw));
  --secondary-darker: rgb(var(--secondary-darker-raw));

  --secondary-accent: rgb(var(--secondary-accent-raw));

  --error: rgb(var(--error-raw));
  --success: rgb(var(--success-raw));
  --info: rgb(var(--info-raw));
  --warning: rgb(var(--warning-raw));
}
