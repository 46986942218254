.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation-name: ripple;
  animation-timing-function: linear;
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}
