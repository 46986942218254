@import 'src/assets/styles/partials/breakpoints',
  'src/assets/styles/partials/shadows', 'src/assets/styles/partials/easing';

.notification-wrapper {
  position: relative;

  justify-content: flex-start;
  align-items: flex-start;

  background-color: var(--base-bg-dark);

  border-radius: 3px;

  padding: 1em 1em 1em 1.5em;

  gap: 0.5em;

  max-width: 400px;
  min-width: 200px;

  transition: box-shadow 150ms ease-in-out, transform 150ms ease-in-out;

  @include shadow-8dp();

  &:not(:last-child) {
    margin-bottom: 0.5em;
  }

  &:hover {
    @include shadow-4dp();
    transform: translateY(2px);
  }

  .border {
    position: absolute;

    height: calc(100% - 2em);
    width: 6px;

    border-radius: 25px;

    top: 1em;
    left: 0.5em;

    background-color: var(--primary);
  }

  .notification-header {
    width: 100%;

    justify-content: space-between;

    .notification-title {
      font-size: 0.8em;
      letter-spacing: 2px;
      color: var(--primary);
      text-transform: uppercase;
    }

    .dismiss-button {
      color: var(--primary);
      cursor: pointer;
    }
  }

  .notification {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    box-sizing: border-box;

    width: 100%;

    position: relative;

    color: var(--secondary-accent);

    .notification-content {
      font-size: 14px;
    }
  }

  .progress-placeholder {
    position: absolute;

    bottom: 0px;
    left: 0px;

    width: 100%;
    height: 5px;

    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    z-index: 100;
  }

  .progress {
    position: absolute;

    bottom: 0px;
    left: 0px;

    width: 100%;
    height: 5px;

    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 110;

    animation: progress;
    animation-timing-function: linear;
  }

  &.error {
    .border {
      background-color: var(--error);
    }

    .progress-placeholder {
      background-color: rgba(var(--error-raw), 0.25);
    }
    .progress {
      background-color: rgba(var(--error-raw), 0.75);
    }
  }

  &.info {
    .border {
      background-color: var(--info);
    }

    .progress-placeholder {
      background-color: rgba(var(--info-raw), 0.25);
    }
    .progress {
      background-color: rgba(var(--info-raw), 0.75);
    }
  }

  &.warning {
    .border {
      background-color: var(--warning);
    }

    .progress-placeholder {
      background-color: rgba(var(--warning-raw), 0.25);
    }
    .progress {
      background-color: rgba(var(--warning-raw), 0.75);
    }
  }

  &.success {
    .border {
      background-color: var(--success);
    }

    .progress-placeholder {
      background-color: rgba(var(--success-raw), 0.25);
    }
    .progress {
      background-color: rgba(var(--success-raw), 0.75);
    }
  }
}

.notification-container {
  z-index: 130;

  position: fixed;
  bottom: 0px;
  right: 0px;

  max-width: 50%;

  padding-bottom: 1em;
  padding-right: 1em;

  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column-reverse;

  box-sizing: border-box;

  gap: 1em;

  overflow: visible;

  transition: height 200ms linear;
}

.notification-transition-enter {
  opacity: 0;
  transform-origin: center center;
  transform: translateX(400px);
}
.notification-transition-enter-active {
  opacity: 1;
  transform-origin: center center;
  transform: translateX(0px);
  transition: transform 500ms ease-in-out, opacity 500ms linear;
}
.notification-transition-exit {
  opacity: 1;
  transform-origin: center center;
  transform: translateX(0px);
}
.notification-transition-exit-active {
  opacity: 0;
  transform-origin: center center;
  transform: translateX(400px);
  transition: transform 500ms ease-in-out, opacity 500ms linear;
}

@keyframes progress {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}
