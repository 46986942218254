.tier-2-preview-renderer {
  font-size: 1rem;

  width: 100%;
  min-width: 100%;

  h2 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .markdown-renderer {
    width: 100%;
    padding-bottom: 2rem;
    font-size: 1rem;
  }

  // Force all tables to have the same width for each column
  table {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    tr {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: row;
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }

    thead,
    tbody,
    tfoot {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
    }

    th,
    td {
      display: block;
      justify-content: flex-start;
      align-items: flex-start;
      text-align: start;
      padding: 0.5rem 1rem;
      padding-left: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 0.9rem;
    }

    // Make each column:
    // - 25%
    // - 30%
    // - 10%
    // - 5%
    // - 30%

    th:nth-child(1),
    td:nth-child(1) {
      width: 30%;
      display: block;
    }
    th:nth-child(2),
    td:nth-child(2) {
      width: 30%;
      display: block;
    }
    th:nth-child(3),
    td:nth-child(3) {
      width: 10%;
      display: block;
    }
    th:nth-child(4),
    td:nth-child(4) {
      width: 5%;
      display: block;
    }
    th:nth-child(5),
    td:nth-child(5) {
      width: 25%;
      display: block;
    }
  }
}

.tier-3-preview-renderer {
  .markdown-renderer {
    font-size: 0.9rem;
    line-height: 2rem;
    p {
      line-height: 2rem;
    }

    .link {
      display: inline-flex;
    }
  }
}
