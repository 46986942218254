.router-skeleton {
  background-color: var(--base-bg-dark);

  .router-skeleton-title {
    font-size: 48px;

    color: var(--primary);

    letter-spacing: 4px;
  }

  .router-skeleton-hint {
    color: var(--secondary-lighter);
  }

  .loader-icon {
    animation: rotate 2000ms linear infinite;
    color: var(--primary);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
