.grid-container {
  width: 100%;

  display: grid;
  column-gap: 24px;
  row-gap: 1em;

  justify-content: center;
  align-content: flex-start;

  &.main {
    grid-template-columns: repeat(12, 76px);
    grid-column-gap: 24px;
  }
}

.grid-item {
  justify-self: stretch;
}

.flex-row {
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  position: relative;
  &.justify-start {
    justify-content: flex-start;
  }
}

.flex-col {
  display: flex;
  flex-direction: column;

  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  position: relative;
}

.flex-group {
  width: 100%;
  height: 100%;
  display: flex;

  flex-wrap: nowrap;

  &.row {
    flex-direction: row;
    align-items: center;

    &.start {
      justify-content: flex-start;
    }
    &.end {
      justify-content: flex-end;
    }
    &.center {
      justify-content: center;
    }
    &.space-between {
      justify-content: space-between;
    }
    &.space-evenly {
      justify-content: space-evenly;
    }
    &.space-around {
      justify-content: space-around;
    }
  }

  &.column {
    flex-direction: column;
    justify-content: center;

    &.start {
      align-items: flex-start;
    }
    &.end {
      align-items: flex-end;
    }
    &.center {
      align-items: center;
    }
    &.space-between {
      align-items: space-between;
    }
    &.space-evenly {
      align-items: space-evenly;
    }
    &.space-around {
      align-items: space-around;
    }
  }

  .group-child.grow {
    flex-grow: 1;
  }
}
