@import 'src/assets/styles/partials/shadows';

.date-picker-wrapper {
  width: 100% !important;
  height: 38px;

  flex-wrap: nowrap;

  * {
    outline: none !important;
  }

  .calendar-icon {
    position: absolute;

    width: 20px;
    height: 20px;

    bottom: 10px;
    right: 10px;

    color: var(--primary);

    transition: 150ms linear;
  }

  &:hover {
    .calendar-icon {
      color: var(--primary);
    }
  }

  .react-datepicker {
    display: flex;
  }

  .react-datepicker-wrapper {
    height: 38px;
    width: 100%;

    color: var(--secondary-accent);

    .react-datepicker__input-container {
      height: 100%;
      width: 100%;

      .date-picker {
        height: 100%;
        width: 100%;
      }

      &:hover {
        color: var(--primary);
      }
    }
  }

  .react-datepicker__tab-loop {
    .react-datepicker {
      @include shadow-6dp();

      border: none;
      border-radius: 2px;

      background-color: white;

      color: var(--primary);

      .react-datepicker__header {
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;

        border: none;
        background-color: rgba(var(--primary), 0.5);

        .react-datepicker__current-month {
          margin: 4px 12px 1em 12px;

          padding-bottom: 1em;

          color: var(--primary);

          font-weight: lighter;

          border-bottom: 1px solid var(--base-bg);
        }

        .react-datepicker__day-names {
          font-weight: normal;

          .react-datepicker__day-name {
            color: var(--secondary-accent);
            margin: 0px 0.5em;
          }
        }
      }

      .react-datepicker__triangle {
        display: none;
      }

      .react-datepicker__navigation {
        margin-top: 0.25em;

        &.react-datepicker__navigation--previous {
          border-right-color: var(--secondary-accent);
        }
        &.react-datepicker__navigation--next {
          border-left-color: var(--secondary-accent);
        }

        &:hover {
          &.react-datepicker__navigation--previous {
            border-right-color: var(--primary);
          }
          &.react-datepicker__navigation--next {
            border-left-color: var(--primary);
          }
        }
      }

      .react-datepicker__month {
        .react-datepicker__week {
          .react-datepicker__day {
            color: var(--primary);

            font-weight: normal;
            margin: 0.5em 0.5em;
            border-radius: 50%;

            transition: background-color 150ms linear;

            // aria-disabled="true" is added to the days that are not in the current month
            &[aria-disabled='true'] {
              color: var(--secondary-accent);
            }

            // react-datepicker__day--today is added to the current day
            &.react-datepicker__day--today {
              color: var(--primary);
              font-weight: bold;
            }

            &.react-datepicker__day--keyboard-selected {
              border-radius: 50%;

              color: var(--primary);

              background-color: var(--base-bg);
            }

            &:hover:not(.react-datepicker__day--keyboard-selected) {
              border-radius: 50%;
              background-color: var(--base-bg);
            }

            &.react-datepicker__day--selected {
              background-color: var(--base-bg-dark);
            }
          }
        }
      }

      .react-datepicker__year {
        .react-datepicker__header {
          height: 40px;
          margin-top: 7px;
        }

        .react-datepicker__year-container {
          .react-datepicker__year-container-text {
            margin: 0.5em 0px;

            transition: color 200ms linear;
            &:hover {
              color: var(--primary);
            }
          }
        }
      }
    }
  }
}
