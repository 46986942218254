.rjsf-markdown-editor {
  position: relative;

  .rjsf-markdown-renderer {
    width: 100%;
    height: auto;

    max-height: 500px;

    overflow: auto;

    border: 1px solid var(--secondary);
    border-radius: 3px;

    padding: 0px 0.5em;

    background-color: var(--base-bg-dark);
  }

  .rjsf-markdown-textarea {
    max-height: 500px;
    height: auto;
  }

  .preview-button {
    position: absolute;
    bottom: 1px;
    right: 1em;

    font-size: 14px;

    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

.md-editor-wrapper {
  width: 100%;
}


.md-editor-with-references {
  padding: 1rem 1rem;

  .md-editor {
    width: unset;
    width: 100%;
  }
}