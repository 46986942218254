@import 'src/assets/styles/partials/colors';

.list-header-grid {
  width: 100%;
  height: 60px;

  background-color: var(--base-bg-dark);
  border-radius: 4px;

  padding: 0px 1em;

  &.compact {
    height: 2rem;
    padding: 0 1em;
    margin: 0;


    .list-header-field-container {
      height: 2rem;
    }
  }

  .list-header-field-container {
    height: 60px;
    align-items: center;

    .list-header-field {
      width: auto;
      height: 100%;

      align-items: center;
      justify-content: flex-start;

      flex-wrap: nowrap;
      overflow: hidden;

      .list-header-field-name {
        font-size: 0.8em;
        letter-spacing: 2px;
        color: var(--primary-lighter);
        font-weight: bold;
        text-transform: uppercase;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .list-header-field-icon {
        color: var(--primary);
      }
    }

    &.list-header-filter-col {
      justify-content: flex-end;
      .list-header-field {
        justify-content: flex-end;
      }
      .filter-icon-wrapper {
        cursor: pointer;
      }
    }

    &.active {
      .list-header-field {
        .list-header-field-name {
          color: rgba(var(primary-raw), 0.6);
        }
      }
    }

    &.sortable {
      cursor: pointer;
      .list-header-field {
        .list-header-field-name {
          margin-right: 1em;
        }
      }

      &:hover:not(.active) {
        .list-header-field {
          .list-header-field-name {
            color: var(--primary-light);
          }
        }
      }
    }
  }
}
