@import 'src/assets/styles/partials/colors';

.element-cell-value {
  width: 100%;

  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;

  .link {
    display: flex;
    flex-direction: row;
  }
}

.actions-cell-value {
  width: 100%;

  justify-content: flex-end;
  align-self: center;

  flex-wrap: nowrap;

  &.center {
    justify-content: center;
  }
  &.start {
    justify-content: flex-start;
  }

  .action-icon-container {
    height: 30px;
    width: 30px;

    border: 1px solid var(--base-bg-darker);
    border-radius: 4px;

    cursor: pointer;

    .action-icon {
      color: var(--primary);
    }

    &:hover {
      background-color: var(--base-bg-darker);

      .action-icon {
        color: var(--primary);
      }
    }
  }
}

.no-elements-container {
  width: 60%;
  margin: 0 auto;

  text-align: center;

  &.show {
    display: block;
  }

  &.hide {
    display: none;
  }
}
