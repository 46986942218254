.form-control {
  position: relative;

  justify-content: center;
  align-items: flex-start;

  flex-wrap: nowrap;

  width: 100%;

  padding: 1em;

  border-radius: 3px;

  background-color: var(--base-bg-dark);

  color: var(--secondary);

  &.empty-control {
    background-color: transparent;
  }

  .form-control-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    width: 100%;

    .form-control-label-text {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      margin-bottom: 0.5em;
      .required-symbol {
        position: relative;

        font-weight: bold;
        font-size: 18px;

        color: var(--primary);
      }
    }

    .form-control-description {
      color: var(--secondary-lighter);
      font-size: 14px;
      margin-bottom: 0.5em;
    }

    .form-control-help {
      color: var(--secondary-lighter);
      font-size: 11px;
      margin: 0.5em 0px 0.5em 4px;
    }

    .form-control-error {
      color: var(--error);
      font-size: 12px;
      margin-top: 0.25em;
    }
  }
}

.input-control {
  .input-control-input {
    width: 100%;

    &.with-error {
      border-color: var(--error);
    }
  }
}

.password-control {
  .input-control-input-wrapper {
    width: 100%;

    position: relative;

    .password-control-icon {
      position: absolute;

      right: 0.5em;
      color: var(--secondary-lighter);

      cursor: pointer;

      &:hover {
        color: var(--secondary);
      }
    }
  }
}

.select-control {
  .select {
    width: 100%;
  }
}

.duration-control {
  .duration-control-wrapper {
    gap: 4px;
    .duration-control-field {
      align-items: flex-start;
      gap: 0px;

      .input {
        max-width: 5rem;
      }
      .duration-control-field__label {
        font-size: 0.75rem;
      }
    }
  }
}

.form-control.skeleton {
  .input-skeleton {
    width: 100%;
  }
}
