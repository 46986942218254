.tier-2-error-modal {
  .tier-2-error-modal-content {
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    .error-item {
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      gap: 4px !important;

      .error-item-field {
      }
      .error-item-errors {
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        gap: 4px !important;
        padding-left: 2rem;

        .error-item-error {
          justify-content: flex-start;
          color: var(--error);
        }
      }
    }
  }
}
