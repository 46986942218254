.rjsf-form-pdf {
  width: 100%;
  .form-control {
    background-color: #FFF;

    .input, textarea, select, .md-editor {
      border: 0;
      background-color: #EEE;
      width: 100%;
    }

    .object-field-wrapper {
      width: 100%;
    }

    .md-editor-toolbar,
    .comment-thread-editor,
    .pdf-hidden, 
    .required-symbol, 
    .comment-thread-messages,
    .rjsf-comment-thread,
    .max-length-indicator {
      display: none;
    }

    .form-control-label-text, .rjsf-field-template, .form-control-label-text, .md-editor-with-references {
      padding: 0;
      margin-bottom: 1rem;
    }

    .rjsf-field-template.is-title:not(.no-title) > .form-control-label > .form-control-label-text {
      padding: 0;
    }

    .mdxeditor-diff-source-wrapper {
      height: 100%;
    }
  }


  .md-editor-wrapper .mdx-editor-element .mdxeditor-rich-text-editor .editable-content {
    height: unset;
    border: 0;
    padding: 1rem;
  }

  
  .md-editor-wrapper .mdx-editor-element .mdxeditor-rich-text-editor {
    height: unset;
  }

  .mdxeditor-diff-source-wrapper, .mdxeditor-diff-source-wrapper {
    height: unset;
    border: 0;
  }
  .mdxeditor-toolbar {
    display: none;
  }

  .md-editor-wrapper {
    border: unset;
  }
}
