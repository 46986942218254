.quality-check-field {
  background-color: transparent;
  position: relative;
  top: -3em;

  .quality-check-input-reason-wrapper {
    margin-top: 1em;
  }

  .catalog-provided-info-edit-row {
    border-left: 5px solid var(--primary);
    padding-left: 1rem;
  }

  .form-control {
    background-color: transparent;

    .quality-check-input {
      width: 100%;
      margin-top: 1em;
    }
  }
}
