.token-group {
  width: auto;
  height: 100%;

  padding: 0.25em;

  flex-wrap: nowrap;

  cursor: pointer;
  transition: background-color 150ms linear;

  &:hover {
    background-color: var(--base-bg-dark);
    .token-block {
      background-color: var(--base-bg-darker);
      .text-span {
        color: var(--secondary);
      }
    }
  }
}

.token-block {
  height: 100%;
  width: auto;

  flex-wrap: nowrap;
  justify-content: flex-start;

  background-color: var(--base-bg-dark);
  border-radius: 3px;

  padding: 0px 0.5em;

  color: var(--secondary);

  transition: background-color 150ms linear;

  .text-span {
    transition: color 150ms linear;
  }

  &.value-block {
    .remove-token-value-icon {
      margin-left: 0.5em;

      min-width: 18px;
      min-height: 18px;

      cursor: pointer;

      transition: color 150ms linear;

      &:hover {
        color: var(--primary);
      }
    }
  }
}
