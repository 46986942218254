@import 'src/assets/styles/partials/shadows', 'src/assets/styles/partials/theme';

.suggestions-content {
  width: auto;
  height: auto;

  min-width: 200px;
  max-height: 300px;

  background-color: var(--base-bg);
  border-radius: 3px;

  padding: 0px;

  @include shadow-4dp();

  .suggestion-content-option {
    width: 100%;

    padding: 0.25em 0.5em;

    cursor: pointer;

    background-color: var(--base-bg);
    color: var(--secondary-light);

    flex-wrap: nowrap;

    transition: background-color 150ms linear, color 150ms linear;

    .suggestion-content-option-default {
      width: 100%;
      justify-content: space-between;
      .fixed-suggestion-icon-wrapper {
        width: auto;
        height: 100%;
        align-items: center;

        .fixed-suggestion-icon {
          color: var(--primary);
        }
      }
    }

    @include dark-theme() {
      background-color: var(--base-bg-dark);
    }

    &:hover,
    &:focus {
      color: var(--secondary);
      background-color: var(--base-bg-dark);
      outline: none;

      @include dark-theme() {
        background-color: var(--base-bg-darker);
      }
    }
  }
}
