.use-case {
  background-color: var(--base-bg-dark);

  padding: 0.5em;

  border-radius: 5px;

  .use-case-input {
    width: 100%;
  }

  .use-case-file-input {
    width: 100%;

    .use-case-file-list {
      z-index: 10;
      .use-case-file {
        width: auto;

        .use-case-file-button {
          padding: 2px;
        }
        .use-case-file-icon {
          width: 14px;
          height: 14px;
        }
        &:hover {
          .use-case-file-delete {
            stroke-width: 2px;
          }
        }
      }
    }
  }
}
