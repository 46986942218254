@import 'src/assets/styles/partials/shadows', 'src/assets/styles/partials/theme';

.tippy-tooltip.tippy-box {
  padding: 0.5em 1em;
  background-color: var(--base-bg);
  color: var(--primary);
  font-size: 14px;
  border-radius: 4px;
  @include shadow-3dp();

  @include dark-theme() {
    background-color: var(--base-bg-dark);
    color: var(--secondary);
  }
}
.tippy-arrow {
  background-color: var(--base-bg);
}
