.rjsf-forward-control {
  .forward-control-title-row {
    .forward-control-title {
      font-size: 1.2em;
    }
  }

  .forward-control-options {
    .forward-control-option {
      padding: 0.5em 1em;

      border-radius: 3px;

      border: 1px solid var(--secondary-lighter);
      background-color: var(--base-bg-dark);
      color: var(--secondary-light);

      cursor: pointer;

      transition: border-color 150ms linear, background-color 150ms linear,
        color 150ms linear;

      &.selected {
        border-color: var(--primary);
        background-color: var(--primary);
        color: var(--primary-accent);
      }
    }
  }
}
