.task-form-wrapper {
  width: 100%;
}

.jupyter-notebook-url-field {
  display: flex;
  flex-direction: column;
  width: 100%;

  .form-control {
    background-color: transparent;
  }
}
