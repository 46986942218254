.max-length-indicator {
  position: absolute;
  right: 0.5em;
  top: 1.25em;
  font-size: 0.7em;

  &.limit-reached {
    color: var(--error);
  }
}
