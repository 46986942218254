@import 'src/assets/styles/partials/shadows',
  'src/assets/styles/partials/breakpoints';

.table {
  width: 100%;
  height: auto;

  box-sizing: border-box;
  border-collapse: collapse;
  background-color: var(--base-bg);

  text-align: center;

  @include shadow-4dp();

  * {
    box-sizing: border-box;
  }

  .thead {
    .tr {
      height: 60px;

      color: var(--primary);

      font-size: 18px;

      border: 1px solid #523ed666;

      .th {
        padding: 0px 1em;
        border-right: 1px solid #523ed666;
        &:last-child {
          border-right: none;
        }
      }
    }
  }

  .tbody {
    font-weight: 300;
    border: 1px solid #523ed666;

    .tr {
      height: 45px;

      background-color: var(--base-bg);

      transition: color 200ms linear, background-color 200ms linear;

      .td {
        padding: 0px 1em;
        border-right: 1px solid #523ed666;
        &:last-child {
          border-right: none;
        }
      }

      &:hover {
        background-color: #523ed666;
      }

      &.selected {
        color: var(--primary);
      }
    }
  }
}
