.spoiler {
  .wrapper {
    display: block;

    overflow: hidden;

    will-change: height;

    transition: height 150ms ease-in-out;

    .spoiler-content {
      position: relative;
      display: block;
    }
  }

  .spoiler-button {
    margin-top: 1em;
  }
}
