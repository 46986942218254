.rjsf-fix-review-comment {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 1em;

  border-radius: 3px;
  border: 1px solid var(--base-bg-darker);

  .review-comment-label {
    font-size: 12px;
    color: var(--secondary-lighter);
  }

  .review-score {
    padding: 0.25em 0.5em;
    font-size: 14px;
    border-radius: 3px;
    color: white;
  }
}
