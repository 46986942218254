.base-layout {
  position: relative;

  padding: 0px;

  .base-layout-header {
    top: 0px;

    width: 100%;
    height: 60px;

    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;

    padding: 0px 1em;

    overflow: hidden;

    border-bottom: 1px solid var(--base-bg-dark);

    .base-layout-header-left {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      width: auto;

      flex-grow: 1;

      overflow: hidden;

      .base-layout-page-title {
        margin: 0px;
        margin-left: 0em;
        font-weight: 300;
        font-size: 1.2rem;

        text-align: start;

        max-width: 90%;
        overflow: hidden;

        color: var(--secondary-lighter);

        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .back-button-container {
        height: 100%;

        cursor: pointer;

        .back-button-icon {
          color: var(--secondary);

          transition: color 150ms linear, transform 150ms ease-in-out;
        }

        &:hover {
          .back-button-icon {
            color: var(--primary);

            transform: translateX(-5px);
          }
        }
      }
    }

    .base-layout-header-right {
      min-width: 250px;

      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .base-layout-content {
    width: 100%;
    height: 100%;

    padding: 2em;

    justify-content: flex-start;
    align-items: flex-start;
  }
}
