.detail-load-error {
  padding: 2em;

  background-color: var(--base-bg-dark);

  max-width: 60%;

  .alert-icon {
    color: var(--primary-lighter);
  }

  .detail-load-error-message {
    .entity {
      color: var(--primary);
    }
  }
}

.list-load-error {
  padding: 2em;

  background-color: var(--base-bg-dark);

  max-width: 60%;

  .alert-icon {
    color: var(--primary-lighter);
  }

  .list-load-error-message {
    .entity {
      color: var(--primary);
    }
  }
  .hint {
    font-size: 12px;
  }
}
