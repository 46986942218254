.catalog-entry-viewer {
  margin-bottom: 1em;
  .catalog-entry-viewer-title {
    font-size: 18px;
    color: var(--primary);
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .catalog-entry {
    margin-left: 1em;

    font-size: 14px;

    cursor: pointer;

    .catalog-entry-name {
    }

    .catalog-entry-icon {
      color: var(--primary);
    }
  }
}
