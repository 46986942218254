.rjsf-field-template.is-title {
  & > .form-control-label {
    & > .form-control-label-text {
      width: 100%;

      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: nowrap;

      font-size: 1.2rem;
      color: var(--secondary-lighter);
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }
  &:not(.no-title) {
    margin: 0px;
    padding: 0px;
    & > .form-control-label {
      & > .form-control-label-text {
        gap: 1em;

        margin-bottom: 0px;
        padding: 0.5em 1em;

        &::after {
          content: '';
          flex-grow: 1;
          height: 4px;
          background-color: var(--base-bg-darker);
        }
        &::before {
          content: '';
          flex-grow: 1;
          height: 4px;
          background-color: var(--base-bg-darker);
        }
      }
    }
  }
}

.field-errors {
  background-color: rgba(var(--primary-raw), 0.1);
  border-radius: 3px;
  margin-top: 1em;
  padding: 0.5em;

  .field-error {
    .field-error-icon {
      color: var(--error);
    }
    .field-error-text {
      font-size: 0.8em;

      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
}
