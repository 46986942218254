.tier-2-index-wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 1em;
  max-width: 300px;

  width: auto;
  height: auto;

  .tier-2-index {
    min-width: 325px;
    height: auto;

    flex-wrap: nowrap;
    align-items: flex-start;

    border-radius: 4px;

    padding: 1em;
    gap: 1em;

    background-color: var(--base-bg-dark);

    .tier-2-index-title {
      color: var(--secondary-light);
      font-size: 1.5em;
    }

    .item {
      flex-wrap: nowrap;

      .item-label-row {
        height: 24px;
        .item-label {
          color: var(--primary-light);
          text-decoration: none;
        }

        .item-state-icon {
          width: 20px;
          &.fully-meet {
            color: var(--success);
          }
          &.not-meet {
            color: var(--warning);
          }
          &.na {
            color: var(--secondary-light);
          }
        }

        .fill-decorator {
          flex-grow: 1;
          height: 2px;
          background-color: var(--base-bg-darker);
        }
      }

      .item-children {
        width: calc(100% - 4px);
        gap: 4px;

        padding-left: 0.75em;
        margin-left: 4px;

        border-left: 2px solid var(--base-bg-darker);
      }

      &.parent > .item-label-row > .item-label {
        font-size: 1rem;
        letter-spacing: 2px;
      }
    }

    .item-label {
      // Truncate long text
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 75%;
    }
  }
}
