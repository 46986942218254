.text-span {
  color: var(--secondary-lighter);

  font-size: 1em;
  line-height: 1.25em;
  font-weight: normal;

  &.ellipsis {
    width: 100%;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.interactive {
    color: var(--primary);

    cursor: pointer;

    transition: color 150ms linear;

    &:hover {
      color: var(--primary-lighter);
    }
  }
}
