.stepper {
  height: auto;

  .step-line {
    flex-grow: 1;

    height: 3px;

    background-color: var(--secondary-lighter);

    transition: background-color 150ms linear;

    &.completed {
      background-color: var(--success);
    }
  }

  .step {
    width: 250px;
    max-width: 250px;

    height: 65px;

    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;

    color: var(--secondary-light);
    border: 2px solid var(--base-bg-dark);

    padding: 0.5em;

    border-radius: 3px;

    transition: background-color 150ms linear, border-color 150ms linear;

    .step-left-side {
      height: 100%;

      justify-content: center;
      align-items: center;

      padding: 0px 0.5em;

      .step-icon-wrapper {
        border: 2px solid var(--base-bg-darker);
        background-color: var(--base-bg-darker);

        border-radius: 50%;
        padding: 0.25em;

        transition: border-color 150ms linear, background-color 150ms linear;

        .step-icon {
          color: var(--secondary);

          transition: color 150ms linear;
        }
      }
    }

    .step-right-side {
      height: 100%;

      justify-content: space-evenly;
      align-items: flex-start;

      flex-wrap: nowrap;

      .step-label {
        font-size: 1em;
      }

      .step-description {
        width: 100%;

        font-size: 0.8em;

        text-overflow: ellipsis;

        color: var(--secondary-lighter);
      }
    }

    &.step-active {
      border-color: var(--primary);

      .step-left-side {
        .step-icon-wrapper {
          background-color: var(--primary);
          border-color: var(--primary);

          .step-icon {
            color: var(--primary-accent);
          }
        }
      }
    }

    &.step-completed {
      border-color: var(--success);
      .step-left-side {
        .step-icon-wrapper {
          background-color: var(--success);
          border-color: var(--success);

          .step-icon {
            color: var(--primary-accent);
          }
        }
      }
    }

    &.step-loading {
    }

    &.step-disabled {
    }

    &.interactive {
      cursor: pointer;

      &:hover {
        &.step-active {
          background-color: rgba(var(--primary-raw), 0.25);
        }

        &.step-completed {
          background-color: rgba(var(--success-raw), 0.25);
        }
      }
    }
  }
}
