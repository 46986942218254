.list {
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;

  .list-element-row {
    width: 100%;
    .list-element-grid {
      position: relative;

      width: 100%;
      height: 60px;

      background-color: var(--base-bg-dark);

      margin: 0.25em 0px;
      padding: 0px 1em;

      border-radius: 5px;

      overflow: hidden;

      transition: background-color 150ms linear, box-shadow 150ms ease-in-out;

      .element-cell {
        width: auto;
        height: 60px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        color: var(--secondary);

        overflow: hidden;
      }
    }

    &.interactive {
      cursor: pointer;

      &:hover {
        .list-element-grid {
          background-color: rgba(var(--primary-raw), 0.2);
        }
      }
    }
  }

  &.scrollable {
    overflow-y: auto;
  }

  &.compact {

    display: flex;
    flex-direction: column;
    height: 14rem;
    flex-wrap: nowrap;

    .list-element-row {
      height: 2rem;

      &:hover {
        .list-element-grid {
          background-color: rgba(var(--primary-raw), 0.12);
        }
      }
      
      .list-element-grid {
        height: 100%;

        margin: 0px;
        padding: 0.5rem 1rem;
        border-radius: 0px;

        .element-cell {
          height: 100%;

          font-size: 0.9em;
        }
      }
    }
  }
}
