.task-history-timeline {
  .task-history-entry {
    height: 100px;
    .timeline-entry-left-wrapper {
      width: 40px;
      height: 100%;

      flex-grow: 1;

      justify-content: space-between;
      align-items: center;

      .icon-wrapper {
        min-height: 30px;
        width: 30px;
        height: 30px;

        border-radius: 50%;

        background-color: var(--base-bg-darker);

        .timeline-icon {
          color: var(--secondary-lighter);
        }
      }

      .timeline-line {
        height: 100%;
        flex-grow: 1;
        width: 3px;
        background-color: var(--base-bg-darker);
      }
    }

    .timeline-entry-data {
      width: 100%;
      flex-grow: 1;
      justify-content: flex-start;
      align-items: flex-start;

      padding-top: 4px;

      .timeline-entry-title {
        color: var(--secondary);
      }

      .timeline-entry-assigne {
        font-size: 14px;
        color: var(--secondary-lighter);
      }

      .timeline-entry-description {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 1em;
        .link {
          font-size: 14px;
        }
      }

      .timeline-entry-dates {
        color: var(--secondary);
        font-size: 12px;
      }
    }

    &.last {
      .timeline-entry-left-wrapper {
        .timeline-line {
          opacity: 0;
        }
      }
    }

    &.completed {
      .timeline-entry-left-wrapper {
        .timeline-line {
          background-color: var(--primary);
        }

        .icon-wrapper {
          background-color: var(--primary);

          .timeline-icon {
            color: white;
          }
          .timed-out {
            opacity: 0.7;
          }
        }
      }
    }
  }

  .show-more-history {
    font-size: 12px;
    a {
      font-size: 12px;
    }
  }
}
