.rjsf-catalog-provided-field {
  .form-control {
    background-color: var(--base-bg);
  }
}

.catalog-provided-info {
  border-left: 5px solid var(--primary);
  padding: 0.5em;
  margin-bottom: 0.5em;

  .catalog-provided-info-wrapper {
    .title {
      font-size: 0.9em;
      font-weight: bold;
    }

    .catalog-provided-info-value {
      font-size: 0.8em;
    }
  }
}

.catalog-provided-info-edit-row {
  .label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: var(--secondary-light);
    font-size: 0.9rem;

    // Disable button hover/active when clicking label
    .button-like {
      margin-top: 0.2rem;
      
      height: 2rem;
      padding: 0 0.5rem;
      &.qa-button-fully_meet_requirements {
        &:hover, &:active, &.active {
          background-color: #56a942;
          border-color: #56a942;
          color: #fff;
        }
        background-color:rgb(223, 240, 222);
        border-color: rgb(66, 169, 92);
        color:  rgb(66, 169, 92);
      }

      &.qa-button-na {
        background-color:rgb(250, 250, 250);
        border-color: rgb(90, 90, 90);
        color:  rgb(90, 90, 90);
        
        &:hover, &:active, &.active {
          background-color: rgb(50, 50, 50);
          border-color: #999;
          color: #fff;
        }
      }

      &.active {
        font-weight: 600;
      }
    }

    .select {
      width: 250px;
    }

    .quality-check-value {
      font-weight: bolder;
      font-size: 1em;

      border-radius: 3px;

      padding: 4px 8px;

      .text-span {
        text-shadow: 0px 0px var(--secondary);
      }

      &.fully_meet_requirements {
        background-color: rgba(var(--success-raw), 0.15);
        .text-span {
          color: var(--success);
        }
      }
      &.partly_meet_requirements {
        background-color: rgba(var(--warning-raw), 0.05);
        .text-span {
          color: var(--warning);
        }
      }
      &.not_meet_requirements {
        background-color: rgba(var(--error-raw), 0.15);
        .text-span {
          color: var(--error);
        }
      }
      &.na {
        background-color: var(--base-bg);
        .text-span {
          color: var(--secondary);
        }
      }
    }
  }
}
