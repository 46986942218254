.list-skeleton {
  width: 100%;

  .list-element-skeleton {
    height: 60px;
    width: 100%;

    background-color: var(--base-bg);
    border-radius: 4px;

    margin: 0.25em 0em;
    padding: 0px 1em;

    &.without-fields {
      padding: 0px;
    }

    .row-cell-skeleton {
      height: 60px;

      display: flex;
      align-items: center;

      overflow: hidden;

      margin: 0 0.5em;
    }
    .skeleton-cell-wrapper {
      width: 100%;
      height: 100%;
    }
  }
}
