.input {
  height: 40px;

  max-height: 40px;

  outline: none !important;

  border-radius: 3px;
  border: 1px solid var(--secondary);

  box-sizing: border-box;

  font-size: 1rem;

  background-color: var(--base-bg);

  padding: 0px 0.5em;

  font-weight: normal;

  color: var(--secondary);

  transition: color 150ms linear, background-color 150ms linear,
    border-color 150ms linear;

  &:focus {
    border-color: var(--primary);
  }

  &:disabled {
    background-color: var(--base-bg-dark);
  }
}

.input-w-error {
  display: flex;
  flex-direction: column;

  width: 100%;

  &.has-error > .input {
    border-color: var(--error);
    color: var(--error);
  }

  .error {
    height: 0px;
    font-size: 0.8em;
    font-weight: 200;
    color: var(--error);

    .error-icon {
      margin-right: 0.5em;
    }
  }

  .input-error-transition-enter {
    transform: translatex(-100px);
    opacity: 0;
  }
  .input-error-transition-enter-active {
    transform: translateY(0px);
    opacity: 1;
    transition: transform 200ms ease-in-out, opacity 200ms linear;
  }
  .input-error-transition-exit {
    transform: translateX(0px);
    opacity: 1;
  }
  .input-error-transition-exit-active {
    transform: translateX(100px);
    opacity: 0;
    transition: transform 200ms ease-in-out, opacity 200ms linear;
  }
}

.limited-input-wrapper {
  width: 100%;
  height: auto;

  position: relative;

  .input {
    width: 100%;

    padding-right: 4em;
  }
}

.input-w-button {
  height: 64px;
  width: auto;

  .input-w-button-input {
    padding-right: 64px;
    width: 100%;
  }

  .input-w-button-button {
    box-sizing: border-box;
    position: relative;

    bottom: 48px;
    left: calc(100% - 48px);

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--primary-accent);

    height: 32px;
    width: 32px;

    border-radius: 50px;
    border: none;

    cursor: pointer;

    outline: none !important;

    transition: background-color 200ms linear;

    .input-w-button-icon {
      width: 16px;
      height: 16px;

      color: var(--primary);

      transition: color 200ms linear;
    }

    &:hover {
      background-color: transparent;

      .input-w-button-icon {
        color: var(--primary-accent);
      }
    }

    &:active {
      outline: none !important;
    }
  }
}

.checkbox {
  appearance: none;

  box-sizing: border-box;

  width: 16px;
  height: 16px;

  border-radius: 1px;
  border: 1px solid var(--primary-accent);

  background-color: transparent;

  cursor: pointer;

  margin: 0.5em;

  pointer-events: all;

  outline: none !important;

  transition: background-color 200ms linear;

  &:checked {
    background-color: var(--primary-accent);

    &::after {
      content: '\2714';
      font-size: 16px;
      position: relative;
      top: -2px;
      left: 0px;
      color: var(--primary);
    }
  }
}

::-webkit-input-placeholder {
  color: var(--secondary-lighter);
  opacity: 1;
}
:-ms-input-placeholder {
  color: var(--secondary-lighter);
  opacity: 1;
}
::-moz-placeholder {
  color: var(--secondary-lighter);
  opacity: 1;
}
::placeholder {
  color: var(--secondary-lighter);
  opacity: 1;
}
