.file-input {
  border: 1px solid var(--primary);
  background-color: var(--base-light);

  padding: 1em;

  box-sizing: border-box;

  transition: background-color 200ms linear;

  border-radius: 8px;

  &:hover {
    background-color: white;
  }

  .hidden-input {
    position: absolute;

    width: 100%;
    height: 100%;

    top: 0px;
    left: 0px;

    z-index: 5;

    opacity: 0;
  }

  .file-list {
    z-index: 10;
  }
}
