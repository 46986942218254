.read-only-message {
  width: 100%;
  color: #999;
  margin-bottom: 1rem;
}

.assigned-to {
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.already-assigned-task-buttons {
  .button {
    margin-right: 1rem;
  }
}

.task-form-wrapper {
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 1em;
  margin-bottom: 3rem;
  &.loading {
    opacity: 0.5;
  }
}
