.rjsf-select {
  height: auto;
  max-height: unset;

  &.catalogue-entry-control {
    .cop-select__multi-value {
      display: none;
    }
  }
}

.selected-catalogue-entry-list {
  .selected-catalogue-entry {
    .catalogue-entry-label {
      font-size: 12px;
    }

    .delete-icon {
      cursor: pointer;

      &:hover {
        color: var(--primary);
      }
    }
  }
}
