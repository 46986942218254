.rjsf-comment-thread {
  .comment-thread-title {
    .title-text {
      font-size: 1.25em;

      text-transform: uppercase;
      letter-spacing: 2px;
    }

    .title-message-count {
      font-size: 0.8em;
      white-space: nowrap;
    }
  }

  &.small {
    .comment-thread-editor {
      flex-direction: row;

      .comment-input {
        width: 100%;
        height: 60px;
      }

      .button {
        width: 145px;
      }
    }

    .rjsf-comment-thread {
      justify-content: flex-start;
    }

    .title-message-form-show {
      font-size: 0.8em;
      align-items: flex-start;
    }

    .rjsf-comment-thread-message {
      font-size: 0.8em;
    }

    .title-message-count {
      font-size: 0.8em;
      white-space: nowrap;
      align-items: flex-start;
    }

    .message-header {
      padding: 0.1em 0.3em;
    }

    .paragraph {
      &.message-content {
        padding: 0.5rem 0.2rem;
        line-height: 1em;
        font-size: 1em;
      }
    }
  }
}

.rjsf-comment-thread-message {
  border: 1px solid var(--base-bg-darker);
  border-radius: 3px;

  .message-header {
    background-color: var(--base-bg-dark);
    padding: 0.5em;
    border-bottom: 1px solid var(--base-bg-darker);

    .message-author {
      .author-name {
        color: var(--secondary);
        font-size: 1em;
      }

      .author-role {
        font-size: 0.6em;
        letter-spacing: 2px;
        font-weight: bold;
        padding: 2px 4px;
        background-color: var(--base-bg-darker);
        border-radius: 3px;
      }

      .author-draft {
        font-size: 0.75em;
        text-transform: uppercase;
      }
    }

    .message-actions {
      .message-date {
        font-size: 0.8em;
        white-space: nowrap;
      }

      .reply-icon {
        color: var(--primary);
        cursor: pointer;
      }
    }
  }

  .message-content {
    width: 100%;

    margin: 0px;
    padding: 1em;

    text-align: start;
    line-height: 1.25em;
  }
}

.comment-thread-editor {
  align-items: flex-end !important;
}

.parent-message-thread-wrapper {
  width: 100%;

  justify-content: flex-end;
  align-items: flex-end !important;

  .parent-message-children {
    width: 95%;

    justify-content: flex-end;
    align-items: flex-end;
  }
}
