@import 'src/assets/styles/partials/ripple',
  'src/assets/styles/partials/breakpoints';

.button,
.button-like {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 0.5em;

  background-color: var(--primary-light-accent);
  color: var(--primary-light);

  box-sizing: border-box;

  height: 38px;

  font-weight: 200;

  border: 1px solid var(--primary-light);

  padding: 0em 1em;

  cursor: pointer;

  overflow: hidden;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.57px;
  box-shadow: none;

  border-radius: 3px;

  outline: none !important;

  transition: color 150ms linear, background-color 150ms linear,
    border-color 150ms linear;

  &:hover {
    background-color: var(--primary-light);
    border-color: var(--primary-light);
    color: var(--primary-light-accent);
  }

  &:active,
  &.active {
    background-color: var(--primary);
    border-color: var(--primary);
    color: var(--primary-accent);
  }

  &:disabled,
  &.disabled {
    background-color: var(--base-bg-darker);
    border-color: var(--base-bg-darker);
    color: var(--primary-lighter);
    box-shadow: none;
    cursor: not-allowed;
    &:hover {
      transform: none;
    }
  }

  &.no-left-border {
    border-radius: 0px 4px 0px 0px;
  }

  &.no-right-border {
    border-radius: 4px 0px 0px 0px;
  }

  .button-loading-indicator {
    animation: spin 1s linear 0s infinite;
  }

  &.small,
  &.small {
    font-size: 14px;
    font-weight: normal;
    padding: 0.5em;
  }
}

.button.text-button {
  height: 32px;

  background-color: var(--base-bg);
  border: none;

  font-weight: normal;

  &:hover {
    color: var(--primary-lighter-accent);
    background-color: var(--primary-lighter);
  }

  &:disabled {
    color: var(--secondary-lighter);
    background-color: var(--base-bg-dark);
  }
}

.button-w-icon {
  svg {
    position: relative;
    width: 22px;
    height: 22px;
    z-index: 10;
    color: var(--primary-lighter);

    transition: color 150ms linear;

    z-index: 0;
  }
  &:disabled {
    svg {
      stroke: var(--primary-lighter);
      pointer-events: none;
    }
  }

  &:hover svg {
    color: var(--primary-accent);
  }
}

.action-icon-button {
  padding: 0.5em;
  height: auto;
  &.rounded {
    border-radius: 3px;
  }
}

.link-button-link {
  text-decoration: none;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
