.textarea {
  width: 100%;

  height: auto;

  outline: none !important;

  border-radius: 3px;
  border: 1px solid var(--secondary);

  box-sizing: border-box;

  font-size: 1rem;

  background-color: var(--base-bg);

  color: var(--secondary);

  padding: 0.5em;

  transition: border-color 300ms linear;

  resize: vertical;

  &:focus {
    border-color: var(--primary);
  }
}

.textarea-limit-wrapper {
  width: 100%;
  height: auto;

  position: relative;

  .max-length-indicator {
    top: unset;
    bottom: 4px;
    right: 1em;
  }
}
