@import 'src/assets/styles/partials/shadows';

.field-list-item {
  padding: 0.5em;

  background-color: var(--base-bg);

  z-index: 1;

  transition: box-shadow 150ms ease-in-out;

  .field-name {
    user-select: none;
    color: var(--secondary);
  }

  .visibility-icon {
    cursor: pointer;

    &:hover {
      color: var(--primary);
    }
  }

  .drag-icon {
    cursor: grab;
    &:hover {
      color: var(--primary);
    }
  }

  &.dragging {
    z-index: 10;
    @include shadow-4dp();
  }

  &.disabled {
    .field-name {
      color: var(--secondary-lighter);
    }
  }
}
