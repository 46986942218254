.calendar-month {
  display: grid;
  grid-template-columns: repeat(7, 1fr);

  width: 100%;
  height: 100%;

  gap: 4px;
  padding: 4px;

  justify-items: stretch;
  align-items: stretch;

  border-radius: 3px;
  border: 1px solid var(--base-bg-darker);
}
