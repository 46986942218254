.simple-tabs-container {
  width: 100%;
  height: 40px;

  justify-content: center;
  align-items: center;

  margin-top: 1em;

  .simple-tabs {
    height: 100%;

    justify-content: space-evenly;
    align-items: flex-end;

    position: relative;

    border-bottom: 1px solid var(--primary);

    .tab-selected-bg {
      height: 40px;
      left: 0px;
      position: absolute;
      background-color: rgba(var(--primary), 0.7);
      transition: transform 200ms ease-in-out;
    }

    .tab {
      height: 100%;
      width: 300px;

      font-size: 0.8em;
      letter-spacing: 4px;
      color: rgba(var(--primary), 0.8);
      font-weight: lighter;
      text-transform: uppercase;

      margin-bottom: 0.5em;

      cursor: pointer;

      transition: color 200ms linear, background-color 200ms linear;

      &.active {
        color: white;
        // background-color: rgba(var(--primary), 0.7);
      }

      &:hover:not(.active) {
        background-color: rgba(var(--primary), 0.15);
      }
    }
  }
}
