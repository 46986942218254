.jupyter-renderer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .cell-renderer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1rem;

    .cell-renderer-label {
      margin-top: 0.2rem;
      margin-right: 0.5rem;
      font-size: 0.8rem;
    }
  }

  .cell-renderer-content-wrapper {
    width: 100%;
    display: block;
  }

  .cell-renderer-content {
    width: calc(100% - 60px);
  }

  .cell-renderer-label {
    width: 50px;
    font-size: 0.9em;
  }

  .cell-renderer-content-wrapper {
    overflow-x: scroll;
  }

  .cell-renderer-comment {
    font-size: 0.8em;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    color: #666;
  }

  .code-entry {
    font-size: 0.9em;
    font-family: monospace;
    background-color: #EEE;
    width: 100%;
    line-height: 1em;
    padding: 0rem 0.5rem;
    border: 1px solid #CCC;
    border-radius: 2px;
  }

  .image-entry {
    margin-top: 1rem;
    width: 100%;

    img {
      border: 1px solid #CCC;
      border-radius: 2px;
    }
  }

  // Styles for h1,hx
  h1 {
    font-size: 1.6em;
    margin-top: 0;
  }

  h2 {
    font-size: 1.5em;
    margin-top: 0;
  }

  h3 {
    font-size: 1.4em;
    margin-top: 0;
  }

  h4 {
    font-size: 1.4em;
    margin-top: 0;
  }

  h5 {
    font-size: 1.2em;
    margin-top: 0;
  }

  h6 {
    font-size: 1em;
    margin-top: 0;
  }

}