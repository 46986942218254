.assessment-images {
  gap: 1em;
}

.assessment-image-summary {
  width: 300px;
  height: 400px;

  justify-content: flex-start !important;
  align-items: flex-start !important;

  background-color: var(--base-bg-dark);
  border-radius: 3px;
  border: 1px solid var(--base-bg-dark);

  padding: 0.5em;

  cursor: pointer;

  transition: background-color 150ms linear, border-color 150ms linear;

  .assessment-image-thumb {
    width: calc(300px - 1em);
    height: 200px;

    border-radius: 3px;
    opacity: 0.75;

    transition: opacity 150ms linear;
  }

  &:hover {
    background-color: var(--base-bg-darker);
    border-color: var(--primary);
    .assessment-image-thumb {
      opacity: 1;
    }
  }
}

.assessment-image-metadata {
  justify-content: flex-start !important;

  .metadata-row {
    .metadata-label {
      font-weight: 500;
      color: var(--secondary);
      margin-right: 0.25em;
    }
  }

  .metadata-show-more {
    font-size: 12px;
    font-style: italic;
  }
}
.assessment-image-caption {
  font-size: 14px;
}

.assessment-image-modal {
  .modal {
    max-width: 1000px;

    .assessment-image {
      .assessment-image-img {
        width: auto;
        height: auto;
        border-radius: 3px;
      }
    }

    .assessment-modal-section {
      width: 100%;

      align-items: flex-start;

      padding: 0.5em;

      border-radius: 3px;
      background-color: var(--base-bg-dark);

      .assessment-modal-section-title {
        font-size: 1.25em;
        font-weight: 500;
        color: var(--secondary);
      }
    }
  }
}

.assessment-image-filter {
  align-items: flex-end !important;

  .assessment-image-filter-label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    width: 250px;
    gap: 0.25em;

    .label-text {
      font-size: 14px;
    }

    .assessment-image-filter-select.select {
      width: 100%;
    }
  }
}
