@import 'src/assets/styles/partials/breakpoints',
  'src/assets/styles/partials/shadows';

.drawer {
  position: fixed;
  box-sizing: border-box;

  z-index: 101;

  transition: transform 300ms ease-in-out, opacity 300ms linear;

  background-color: var(--base-bg);

  @include shadow-4dp();

  .drawer-close-icon {
    color: var(--primary);
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0.5em;
    cursor: pointer;
    z-index: 10;
  }

  &.pos-bottom {
    width: 100%;
    height: 100%;
    bottom: 0px;
    left: 0px;
    @include shadow-to-top();
    .drawer-close-icon {
      right: 0.5em;
      transform: rotate(-90deg);
    }
  }
  &.pos-left {
    height: 100%;
    top: 40px;
    left: 0px;
    width: 500px;
    .drawer-close-icon {
      right: 0.5em;
    }
  }
  &.pos-right {
    height: calc(100% - 40px);
    top: 40px;
    right: 0px;
    width: 500px;
    .drawer-close-icon {
      left: 0.5em;
      transform: rotate(180deg);
    }
  }

  &.opened {
    opacity: 1;
    &.pos-bottom {
      transform: translateY(0px);
    }
    &.pos-left,
    &.pos-right {
      transform: translateX(0xp);
    }
  }

  &.closed {
    opacity: 0;
    &.pos-bottom {
      transform: translateY(100%);
    }
    &.pos-left {
      transform: translateX(-100%);
    }
    &.pos-right {
      transform: translateX(100%);
    }
  }

  .drawer-body {
    height: 100%;
    width: 100%;
    padding: 1em;
    z-index: 9;
    overflow-y: auto;
    justify-content: unset;
    align-items: unset;
    box-sizing: border-box;
  }
}
