@import 'src/assets/styles/partials/theme';

.token-filter-buttons {
  width: auto;
  height: 100%;

  flex-grow: 0;

  .token-filter-button {
    height: 100%;
    width: 40px;

    background-color: var(--base-bg);
    color: var(--primary);

    border-radius: 3px;

    cursor: pointer;

    transition: background-color 150ms linear;

    &:hover {
      background-color: var(--base-bg-darker);
    }

    @include dark-theme() {
      color: var(--primary-light);
    }
  }
}
