.radio-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  width: auto;

  background-color: var(--base-bg-dark);

  margin: 0px 0.5em;

  border-radius: 3px;

  padding: 0.5em 1em;

  color: var(--secondary-lighter);

  transition: color 150ms linear;

  cursor: pointer;
  transition: background-color 150ms linear, border-radius 150ms linear;

  .radio {
    display: none;
    visibility: hidden;
    width: 0px;
    height: 0px;
  }
  &.checked {
    background-color: var(--primary-lighter);

    color: var(--primary-accent);
  }

  &:hover:not(.checked) {
    background-color: var(--base-bg-darker);
  }

  &.rounded {
    border-radius: 25px;
  }
}

.radio-group {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  border: none;

  .radio-group-legend {
    color: var(--primary);
    font-size: 1.2em;
    letter-spacing: 2px;
    margin-bottom: 1em;
  }
}
