.rjsf-approve-reject-field {
  flex-wrap: nowrap;
  .approve-reject-textarea-wrapper {
    flex-grow: 1;
    width: 100%;
    .approve-reject-textarea {
      width: 100%;
    }

    .approve-reject-comment {
      width: 100%;

      text-align: start;
      font-size: 14px;
    }
  }

  .approve-reject-control {
    .approve-reject-icon-wrapper {
      gap: 0.75em;

      padding: 0.25em 0.75em;

      border-radius: 3px;
      border: 1px solid var(--base-bg-darker);

      background-color: var(--base-bg);

      cursor: pointer;

      transition: background-color 150ms linear, color 150ms linear;

      &:hover {
        background-color: var(--base-bg-dark);
      }

      &.active {
        color: white;
        &.approve {
          background-color: var(--success);
        }
        &.reject {
          background-color: var(--error);
        }
      }
    }

    .aprove-text {
      display: flex;
      justify-content: flex-end;
      gap: 0.75em;
      padding: 0.25em 0.75em;

      border-radius: 3px;
      border: 1px solid var(--base-bg-darker);

      background-color: var(--base-bg);

      color: white;

      &.approved {
        background-color: var(--success);
      }

      &.rejected {
        background-color: var(--error);
      }
    }
  }
}
