.markdown-renderer {
  color: var(--secondary);

  font-size: 0.8em;
  .md-paragraph {
    font-size: 1em;
    color: var(--secondary);
  }

  blockquote {
    margin-left: 1em;
    padding-left: 1em;
    border-left: 4px solid var(--base-bg-darker);
  }
}
