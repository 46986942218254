.calendar-day {
  width: 100%;
  height: 100%;

  background-color: var(--base-bg);

  justify-content: space-between;
  align-items: flex-start;

  justify-self: stretch;
  align-self: stretch;

  overflow: hidden;

  border-radius: 3px;

  cursor: pointer;

  transition: background-color 150ms linear;

  .calendar-date-wrapper {
    width: 100%;
    padding: 2px;

    justify-content: flex-end;

    .calendar-date {
      text-align: end;

      font-size: 0.7em;
      font-weight: 500;
      color: var(--secondary);
    }
  }

  .calendar-date-events {
    flex-grow: 1;

    justify-content: flex-end;

    overflow: hidden;
  }

  &.disabled {
    cursor: default;
    opacity: 0.1;
    pointer-events: none;
    .calendar-date-wrapper {
      .calendar-date {
        // color: var(--base-bg-darker);
      }
    }
  }

  &.today {
    .calendar-date-wrapper {
      .calendar-date {
        color: var(--primary);
      }
    }
  }

  &:hover:not(.disabled):not(.today) {
    background-color: var(--base-bg-dark);
  }
}
