@import 'src/assets/styles/partials/breakpoints',
  'src/assets/styles/partials/shadows';

@mixin responsive {
  align-items: flex-end;

  .modal {
    height: 90vh;

    border-radius: 16px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    border: none;

    .modal-body {
      padding: 0px;
    }
  }
}

.modal-backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;

  top: 0px;
  left: 0px;

  background-color: #00000044;
  backdrop-filter: blur(3px);
  color: var(--base-dark);

  z-index: 1000;

  transition: opacity 200ms linear;
  .modal {
    width: auto;
    height: auto;

    max-width: 70%;
    max-height: 70%;

    justify-content: flex-start;
    flex-wrap: nowrap;

    box-sizing: border-box;

    border: 1px solid var(--base-bg-darker);
    border-radius: 3px;
    background-color: white;

    @include shadow-16dp();

    transition: transform 200ms ease-in-out, opacity 200ms linear;

    .modal-header {
      position: relative;

      width: 100%;

      justify-content: flex-start;

      padding: 0.5em 1em;

      color: var(--secondary-lighter);

      border-bottom: 1px solid var(--base-bg-dark);

      .modal-header-content {
        max-width: 90%;

        justify-content: flex-start;

        overflow: hidden;
        text-overflow: ellipsis;

        font-size: 24px;
      }

      .modal-close-icon {
        position: absolute;
        right: 1em;
        cursor: pointer;
      }
    }

    .modal-body {
      width: 100%;

      justify-content: flex-start;
      align-items: center;

      max-height: 1000px;

      padding: 1em;

      overflow: auto;

      color: var(--secondary-light);
    }

    .modal-footer {
      width: 100%;

      border-top: 1px solid var(--base-bg-dark);

      padding: 1em;
    }
  }

  &.confirmation-modal {
    .modal {
      width: 540px;
      height: 370px;

      .confirmation-modal-actions {
        width: 100%;

        justify-content: space-evenly;
        .button {
          width: 40%;
        }
      }
    }
  }

  @include respond-to(small) {
    @include responsive();
  }

  &.mobile {
    @include responsive();
  }
}

.modal-transition-enter {
  &.modal-backdrop {
    opacity: 0;
    .modal {
      opacity: 0;
      transform: translateY(50px);
    }
    &.mobile {
      .modal {
        transform: translateY(100%);
      }
    }
  }
}
.modal-transition-enter-active {
  &.modal-backdrop {
    opacity: 1;
    .modal {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}
.modal-transition-exit {
  &.modal-backdrop {
    opacity: 1;
    .modal {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}
.modal-transition-exit-active {
  &.modal-backdrop {
    opacity: 0;
    .modal {
      opacity: 0;
      transform: translateY(-50px);
    }
    &.mobile {
      .modal {
        transform: translateY(100%);
      }
    }
  }
}
