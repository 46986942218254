.calendar-event {
  width: 100%;
  height: 20px;

  padding: 2px 4px;

  overflow: hidden;

  transition: border-color 150ms linear;

  .calendar-event-name {
    color: var(--secondary-light);
    text-align: center;
    font-size: 0.75em;
  }

  &.event-start {
    width: 90%;
    align-self: flex-end;

    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;

    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-left: 1px solid transparent;
  }

  &.event-end {
    width: 90%;
    align-self: flex-start;

    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;

    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-right: 1px solid transparent;
  }

  &.event-between {
    width: calc(100% + 2px);
    align-self: center;
    border-radius: 0px;

    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }

  &.one-day-event {
    width: 90%;
    align-self: center;

    border-radius: 16px;

    border: 1px solid transparent;
  }

  &.hovered {
    border-color: var(--primary);
  }
}
