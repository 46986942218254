.full-page {
  position: absolute;

  top: 0px;
  left: 0px;

  width: 100%;
  height: 100%;

  box-sizing: border-box;
}

.full-page-backdrop {
  background-color: #33333333;
}
