@import 'src/assets/styles/partials/theme';

.form-data-group {
  .form-data-group-title {
    position: relative;

    font-size: 1.5em;

    color: var(--secondary);
    font-weight: lighter;
  }

  .form-data-decoration-line {
    border: 2px solid var(--base-bg-darker);

    flex-grow: 1;
  }

  .form-data-type-icon {
    color: var(--primary-light);
  }
}

.form-data-field {
  padding: 1em;

  background-color: var(--base-bg-dark);

  justify-content: flex-start;

  border-radius: 3px;

  .form-data-field-title {
    color: var(--primary);
    font-size: 1em;
    letter-spacing: 2px;

    @include dark-theme() {
      color: var(--primary-lighter);
    }
  }

  .form-data-field-description {
    color: var(--secondary-lighter);
    font-size: 0.8em;
  }
}

.form-data-string-type-value {
  color: var(--secondary);
}

.form-data-bool-type-value {
  color: var(--secondary);
}

.form-data-array-wrapper {
  width: 100%;

  justify-content: flex-start;
  align-items: center;

  gap: 0.5em;

  .form-data-array-type-value {
    color: var(--secondary);

    background-color: var(--base-bg-darker);

    padding: 0.25em 0.5em;
    border-radius: 3px;
  }
}

.form-data-md-wrapper {
  .form-data-md-renderer {
    color: var(--secondary);
  }
}

.form-data-images-wrapper {
  justify-content: flex-start;
  margin-top: 0.5em;

  .qar-view-image-wrapper {
    width: 100px;
    height: 120px;

    .qar-view-image {
      width: 100px;
      height: 100px;
      border-radius: 3px;
    }

    .qar-view-image-text {
      font-size: 0.8em;
      text-transform: uppercase;
    }
  }
}

.form-data-assessment-image-wrapper {
  gap: 1em;

  background-color: var(--base-bg);
}

.form-data-use-cases {
  flex-wrap: wrap;

  .use-case {
    background-color: white;
    padding: 0.5em;
    border-radius: 5px;
    width: calc(50% - 0.5em);

    .use-case-title {
      font-size: 20px;
      font-weight: 300;
      color: var(--primary);
    }

    .use-case-comment {
      color: var(--secondary-light);

      .spoiler-content {
        top: 0px;
      }
    }

    .use-case-attachments {
      .use-case-attachment-title {
        color: var(--secondary);
        font-size: 0.8em;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-top: 1em;
      }

      .use-case-attachment {
        margin-left: 2em;
        cursor: pointer;
      }
    }
  }
}
