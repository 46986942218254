.token-filter {
  width: 100%;
  height: 100%;

  flex-grow: 1;

  background-color: var(--base-bg);
  border-radius: 3px;

  padding: 4px;

  .token-filter-input-group {
    flex-grow: 1;

    height: 100%;

    input {
      height: 100%;
      width: 100%;

      font-size: 1em;

      border: none;

      outline: none;

      background-color: var(--base-bg);
      color: var(--secondary);
    }
  }
}
