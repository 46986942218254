.form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  flex-wrap: wrap;

  label {
    display: flex;
    flex-direction: column;
    padding: 0.5em;
    margin-bottom: 0.5em;
    flex-grow: 1;
    flex-shrink: 1;
    box-sizing: border-box;

    color: var(--primary);

    .input {
      margin-top: 1em;
    }
  }

  fieldset {
    border-color: var(--primary);
    border-radius: 4px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    flex-flow: row;
    flex-wrap: wrap;

    legend {
      position: relative;
      font-size: 1.2em;
      color: var(--primary);
      margin: auto;
      padding: 0px 2em;
    }
  }
}
