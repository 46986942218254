.fancy-checkbox {
  background-color: var(--base-bg-dark);

  padding: 1em;

  border-radius: 3px;

  cursor: pointer;

  transition: background-color 150ms linear;

  .fancy-checkbox-left {
    .label {
      color: var(--primary);
      font-size: 1em;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }

  .fancy-checkbox-right {
    width: 50px;
    .icons-wrapper {
      position: relative;

      width: 50px;
      height: 50px;

      .circle-icon {
        position: absolute;

        color: var(--secondary-lighter);

        transition: color 300ms linear;
      }
      .check-icon {
        position: absolute;

        color: var(--primary);
      }
    }
  }

  &:hover {
    background-color: var(--base-bg-darker);
  }

  &.checked {
    .fancy-checkbox-right {
      .icons-wrapper {
        .circle-icon {
          color: var(--primary);
        }
      }
    }
  }
}
