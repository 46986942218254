$breakpoints: (
  'small': (
    max-width: 767px
  ),
  'medium': (
    max-width: 992px
  ),
  'large': (
    max-width: 1200px
  )
);

// Very important!
// When using multiple breakpoints they must be orderer from mayor width to minor:
/*
  THIS WILL WORK AS EXPECTED
  @include respond-to(medium) {
    ...
  }
  @include respond-to(small) {
    ...
  }

  THIS WILL ONLY APPLY "medium" BREAKPOINT STYLES
  @include respond-to(small) {
    ...
  }
  @include respond-to(medium) {
    ...
  }
*/

@mixin respond-to($name) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $name) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $name))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}


.hide-on-small {
  @include respond-to(small) {
    display: none;
  }
}

.show-on-small {
  @include respond-to(large) {
    display: none;
  }
  @include respond-to(medium) {
    display: none;
  }
}
