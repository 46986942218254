.filter-container {
  width: 100%;
  height: 60px;

  overflow: hidden;

  transition: height 100ms ease-in-out;

  &.show {
    height: 60px;
  }

  &.hide {
    height: 0px;
  }
}

.list-filter-grid {
  height: 60px;
  width: 100%;

  background-color: var(--base-bg-dark);

  border-radius: 3px;

  overflow: hidden;
}

.list-filter-group {
  height: 60px;
  width: 100%;

  background-color: var(--base-bg-dark);

  border-radius: 3px;

  padding: 0.5em;
}
