@import 'src/assets/styles/partials/shadows', 'src/assets/styles/partials/theme';

.pagination-container {
  width: 100%;
  justify-content: flex-end;
  align-items: center;

  .pagination {
    height: 50px;

    width: fit-content;

    box-sizing: border-box;

    flex-wrap: nowrap;

    gap: 8px;

    font-size: 18px;

    background-color: var(--base-bg);

    .pagination-button {
      box-sizing: border-box;

      height: 40px;
      width: 40px;

      user-select: none;
      cursor: pointer;

      color: var(--primary-light);

      border-radius: 4px;

      background-color: var(--base-bg-dark);

      transition: color 100ms linear, background-color 100ms linear;

      &:hover:not(.active):not(.ellipsis) {
        color: var(--primary);
        background-color: var(--base-bg-darker);
      }

      &.active {
        cursor: default;
        color: var(--base-bg);
        background-color: rgba(var(--primary-raw), 0.5);

        @include dark-theme() {
          color: var(--secondary);
          background-color: var(--base-bg-darker);
        }
      }
    }
  }

  .pagination-info-row {
    width: auto;
    font-size: 12px;
    color: var(--secondary-lighter);
  }

  .pagination-info-row {
    .selected-page-size {
      color: var(--primary);
      cursor: pointer;
      margin: 0px 4px;
    }
  }

  .page-size-options-container {
    background-color: var(--base-bg);
    padding: 0.5em;

    border-radius: 3px;

    @include shadow-2dp();

    .page-size-option {
      cursor: pointer;

      &:hover:not(.selected) {
        color: var(--primary);
      }

      &.selected {
        color: var(--primary);
      }
    }
  }

  .list-settings-container {
    width: auto;
  }
}
