.rjsf {
  width: 100%;
  height: auto;

  .object-field-wrapper {
    width: 100%;

    > .form-control {
      background-color: var(--base-bg);

      margin: 0.25em 0;
    }
  }

  .rjsf-input {
    width: 100%;
  }
}
