@import 'src/assets/styles/partials/theme';

.link {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;

  color: var(--primary);

  cursor: pointer;

  overflow: hidden;


  position: relative;

  text-decoration: none;

  &:hover {
    text-decoration: underline solid var(--primary);
  }

  @include dark-theme() {
    color: var(--primary-lighter);
  }

  span {
    display: flex;
    flex-direction: row;
  }
}
